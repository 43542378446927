import React, { useRef } from "react";
import "../App.css";
import About from "./About";
import Background from "./Background";
import HomeImage from "./HomeImage";
import ServicesMobile from "./ServicesMobile";
import ContactMobile from "./ContactMobile";

function Mobile() {
  const ref = useRef();
  const refContact = useRef();
  const refAbout = useRef();
  const scroll = (ref) => {
    window.scrollTo({ behavior: "smooth", top: ref.current.offsetTop });
  };

  return (
    <>
      <div id="header-mobile">
        <div className="nav-title">
          <h1>BRUCE CURRIE</h1>
          <h3>Private Investigator</h3>
        </div>
      </div>
      <div className="buttons-mobile">
        <button
          onClick={() => {
            scroll(refAbout);
          }}
        >
          About
        </button>
        <button
          onClick={() => {
            scroll(ref);
          }}
        >
          Services
        </button>
        <button
          onClick={() => {
            scroll(refContact);
          }}
        >
          Contact
        </button>
      </div>
      <div className="content-mobile">
        <HomeImage />
        <span ref={refAbout}></span>
        <About />
        <Background />
      </div>
      <span className="placeholder-span" ref={ref}></span>
      <ServicesMobile />
      <span className="placeholder-contact-span" ref={refContact}></span>
      <ContactMobile />
      <div id="footer-mobile">
        <div className="footer-section-mobile footer-contact">
          <p>TAURANGA</p> <p>P O Box 10004,</p> <p>Bayfair,</p>
          <p> Mt Maunganui 3152</p>
          <p>NEW ZEALAND</p>
        </div>
        <div className="footer-section-mobile">
          <p>
            <a className="email-phone-link" href="tel:+6475757547">
              PH: 07 575 7547
            </a>
          </p>
          <p>
            <a className="email-phone-link" href="tel:+64278441349">
              PH: 027 844 1349
            </a>
          </p>
          <p>
            <a
              className="email-phone-link"
              href="mailto:bruce@brucecurriepi.co.nz"
            >
              bruce@brucecurriepi.co.nz
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default Mobile;
