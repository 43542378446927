import React from "react";
import NetlifyForm from "react-netlify-form";

export default function Contact() {
  return (
    <>
      <div className="contact">
        <form className="contact-form" name="contact" method="post">
          <h1 className="contact-title">Contact</h1>
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label>
              Your Name: <input type="text" name="name" />
            </label>
          </p>
          <p>
            <label>
              Your Email: <input type="email" name="email" />
            </label>
          </p>
          <p>
            <label>
              Message: <textarea rows="4" name="message"></textarea>
            </label>
          </p>
          <p>
            <button type="submit">Send</button>
          </p>
        </form>
      </div>
    </>
  );
}
